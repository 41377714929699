var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',[_c('v-list-item',{staticClass:"ml-4",attrs:{"to":"/release"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-note-text-outline')}}),_vm._v("リリースノート ")],1)],1)],1),_c('v-list-item',{staticClass:"ml-4",attrs:{"to":"/help"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption"},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-help-circle-outline')}}),_vm._v("Help ")],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-4","align":"center"}},[_c('div',[_vm._v("ver,"+_vm._s(_vm.version))])]),_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l(([
          { icon: 'mdi-home', text: 'Home', to: '/users/dashboard', disabled: false },
          { icon: _vm.Icons.form, text: 'フォーム関連', to: '/forms/dashboard', disabled: !_vm.getEnabled('Form') },
          { icon: _vm.Icons.flow, text: '営業フロー報告', to: '/managements/flow', disabled: !_vm.getEnabled('Flow') },
          { icon: _vm.Icons.facility, text: '施設管理(作成中)', to: '/managements/facility', disabled: !_vm.getEnabled('Facility') },
          { icon: _vm.Icons.schedule, text: 'スケジュール(作成中)', to: '/managements/schedule', disabled: !_vm.getEnabled('Schedule') },
          { icon: _vm.Icons.analyse, text: '売上進捗', to: '/managements/analyse', disabled: !_vm.getEnabled('Analyse') },
          { icon: _vm.Icons.termAnalyse, text: '予実管理', to: '/managements/analyseTerm?viewMode=term', disabled: !_vm.getEnabled('Analyse') },
          {
            icon: 'mdi-star-four-points-outline',
            text: '営業ポイント成績表',
            to: '/managements/point',
            disabled: !_vm.getEnabled('Point'),
          },
          {
            icon: 'mdi-currency-usd',
            text: '営業マージン集計表',
            to: '/managements/Mergin',
            disabled: !_vm.getEnabled('Mergin'),
          },
          { icon: 'mdi-google-spreadsheet', text: '売上伝票', to: '/managements/sales', disabled: !_vm.getEnabled('Sales') },
          { icon: _vm.Icons.master, text: 'マスタ(作成中)', to: '/managements/data', disabled: !_vm.getEnabled('Master') },
          { icon: _vm.Icons.flow, text: '受注物件状況一覧', to: '/managements/flow/list1', disabled: !_vm.getEnabled('DemoList') } ]),function(item,index){return [(!item.disabled)?_c('v-list-item',{key:index,attrs:{"link":"","to":item.to}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1):_vm._e()]})],2)],1),_c('v-app-bar',{attrs:{"flat":"","dense":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"text":"","to":"/users/dashboard"}},[_vm._v("Liberty 社内業務管理")])],1)]),_c('v-spacer'),(_vm.isLogined)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"indigo","dark":""}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"white--text",attrs:{"size":"35","color":"blue-grey lighten-3"}},[_vm._v(" "+_vm._s(_vm._f("trim")(_vm.$store.getters.user.name,1))+" ")])],1)]}}],null,false,4179089158),model:{value:(_vm.userMenu.show),callback:function ($$v) {_vm.$set(_vm.userMenu, "show", $$v)},expression:"userMenu.show"}},[_c('UserMenu',{attrs:{"show":_vm.userMenu.show}})],1):_vm._e()],1),_c('v-main',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view',{key:_vm.$route.path})],1)],1)],1),_c('v-footer',{staticClass:"indigo lighten-1 white--text",attrs:{"padless":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }