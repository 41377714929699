<template>
  <XSheet :loading="initializing">
    <template v-slot="{ loaded }">
      <v-card v-if="loaded">
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="blue-grey lighten-3" class="white--text">
              {{ editor.name | trim(1) }}
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ editor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ editor.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon to="/users/dashboard">
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <div class="text-left">
                <v-btn class="text-caption" @click="onMoveRecord" small outlined
                  ><v-icon v-text="'mdi-currency-usd'" left />営業成績確認</v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <div class="text-left">
                <div class="ma-2">
                  <div class="text-caption">支店</div>
                  <span v-for="(branch, index) in editor.branchs" :key="`branch${index}`">
                    <v-chip small>{{ branch.name }}</v-chip>
                  </span>
                  <v-chip v-if="editor.branchs.length == 0" small>支店未設定</v-chip>
                </div>

                <div class="ma-2">
                  <div class="text-caption">チーム</div>
                  <v-chip v-for="(team, index) of editor.teams" small :key="index">{{ team.name }}</v-chip>
                </div>

                <div class="mt-4 ma-2">
                  <div class="text-caption"><v-icon v-text="'mdi-email-multiple-outline'" small />メール送信</div>
                  <ul>
                    <li v-for="(val, index) of editor.meta.emails.filter((a) => a.isSend)" small :key="index">
                      <span class="text-caption">{{ val.email }} ({{ val.type | emailType }})</span>
                    </li>
                  </ul>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-card-actions>
          <v-btn text color="warning" to="/Signout"><v-icon v-text="'mdi-logout-variant'" left /> ログアウト</v-btn>
          <v-spacer />
          <v-btn text @click="resetPassword.show = true" small>パスワードリセット</v-btn>
        </v-card-actions>
      </v-card>

      <ValidationObserver v-slot="observer">
        <v-dialog persistent scrollable v-model="resetPassword.show" max-width="500">
          <ResetPassword
            :observer="observer"
            :show="resetPassword.show"
            @commit="resetPassword.show = false"
            @cancel="resetPassword.show = false"
          />
        </v-dialog>
      </ValidationObserver>
    </template>
  </XSheet>
</template>
<script>
import ResetPassword from './ResetPassword';
export default {
  components: { ResetPassword },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {},

  data: () => ({
    initializing: true,
    //
    editor: {
      code: null,
      email: null,
      name: null,
      branchs: [],
      team: null,
      teams: [],

      meta: null,

      password: null,
      confirmedPassword: null,
    },

    resetPassword: {
      show: false,
    },
  }),

  methods: {
    onLoaded() {
      //
      this.initializing = true;

      this.get('employee', { employeeCode: this.$store.getters.user.code }).then((success) => {
        let data = success.data;

        this.editor.code = data.code;
        this.editor.email = data.email;
        this.editor.name = data.name;
        this.editor.branchs = data.branchs;
        this.editor.team = data.team;
        this.editor.teams = data.teams;

        this.editor.meta = data.meta;

        this.initializing = false;
      });
    },

    onMoveRecord() {
      this.$router.push({ path: '/users/record' });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>