<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet color="grey lighten-4" class="pa-3" align="center">
        <!-- <v-btn icon x-large to="/Account"> Home </v-btn> -->

        <div>ver,{{ version }}</div>
      </v-sheet>

      <v-list dense nav>
        <template
          v-for="(item, index) in [
            { icon: 'mdi-home', text: 'Home', to: '/users/dashboard', disabled: false },
            { icon: Icons.form, text: 'フォーム関連', to: '/forms/dashboard', disabled: !getEnabled('Form') },
            { icon: Icons.flow, text: '営業フロー報告', to: '/managements/flow', disabled: !getEnabled('Flow') },
            { icon: Icons.facility, text: '施設管理(作成中)', to: '/managements/facility', disabled: !getEnabled('Facility') },
            { icon: Icons.schedule, text: 'スケジュール(作成中)', to: '/managements/schedule', disabled: !getEnabled('Schedule') },
            { icon: Icons.analyse, text: '売上進捗', to: '/managements/analyse', disabled: !getEnabled('Analyse') },
            { icon: Icons.termAnalyse, text: '予実管理', to: '/managements/analyseTerm?viewMode=term', disabled: !getEnabled('Analyse') },
            {
              icon: 'mdi-star-four-points-outline',
              text: '営業ポイント成績表',
              to: '/managements/point',
              disabled: !getEnabled('Point'),
            },
            {
              icon: 'mdi-currency-usd',
              text: '営業マージン集計表',
              to: '/managements/Mergin',
              disabled: !getEnabled('Mergin'),
            },
            { icon: 'mdi-google-spreadsheet', text: '売上伝票', to: '/managements/sales', disabled: !getEnabled('Sales') },
            { icon: Icons.master, text: 'マスタ(作成中)', to: '/managements/data', disabled: !getEnabled('Master') },
            { icon: Icons.flow, text: '受注物件状況一覧', to: '/managements/flow/list1', disabled: !getEnabled('DemoList') },
          ]"
        >
          <v-list-item v-if="!item.disabled" link :to="item.to" :key="index">
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
          <!-- <v-divider v-if="divider" :key="`${index}_divider`" /> -->
        </template>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-list-item class="ml-4" to="/release">
            <v-list-item-content>
              <v-list-item-title class="text-caption"> <v-icon v-text="'mdi-note-text-outline'" left />リリースノート </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ml-4" to="/help">
            <v-list-item-content>
              <v-list-item-title class="text-caption"> <v-icon v-text="'mdi-help-circle-outline'" left />Help </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar flat dense app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <div class="d-flex align-center">
          <v-btn text to="/users/dashboard">Liberty 社内業務管理</v-btn>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <template v-if="getEnabled('Form')">
        <v-btn to="/forms/dashboard" text small
          ><v-icon>{{ Icons.form }}</v-icon> フォーム関連</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Flow')">
        <v-btn to="/managements/flow" text small
          ><v-icon>{{ Icons.flow }}</v-icon> 営業フロー報告</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Facility')">
        <v-btn to="/managements/facility" text small
          ><v-icon>{{ Icons.facility }}</v-icon> 施設管理(作成中)</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Schedule')">
        <v-btn to="/managements/schedule" text small
          ><v-icon>{{ Icons.schedule }}</v-icon> スケジュール(作成中)</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Analyse')">
        <v-btn to="/managements/analyse" text small
          ><v-icon>{{ Icons.analyse }}</v-icon> 売上進捗</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Sales')">
        <v-btn to="/managements/sales" text small
          ><v-icon>{{ Icons.analyse }}</v-icon> 売上伝票</v-btn
        >
        <v-spacer></v-spacer>
      </template>

      <template v-if="getEnabled('Master')">
        <v-btn to="/managements/data" text small
          ><v-icon>{{ Icons.master }}</v-icon> マスタ(作成中)</v-btn
        >
        <v-spacer></v-spacer>
      </template> -->

      <!--     

      <v-text-field
        append-icon="mdi-microphone"
        class="mx-4"
        flat
        hide-details
        label="Search"
        prepend-inner-icon="mdi-magnify"
        solo-inverted
        clearable
        @keyup.enter="onSearch"
      /> -->

      <v-menu v-if="isLogined" v-model="userMenu.show" :close-on-content-click="false" :nudge-width="300" offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="indigo" dark v-bind="attrs" v-on="on">
            <v-avatar size="35" color="blue-grey lighten-3" class="white--text">
              {{ $store.getters.user.name | trim(1) }}
              <!-- <img
                :src="$store.getters.user.avatar.src"
                :alt="$store.getters.user.name"
              > -->
            </v-avatar>
          </v-btn>
        </template>

        <UserMenu :show="userMenu.show" />
      </v-menu>

      <!-- <span class="text-caption" v-text="$vuetify.breakpoint.name" /> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <!-- <div class="c-body">
        <main class="c-main"> -->
      <transition name="fade" mode="out-in">
        <v-container fluid>
          <router-view :key="$route.path"></router-view>
        </v-container>
      </transition>
      <!-- </main>
      </div> -->
    </v-main>

    <v-footer padless class="indigo lighten-1 white--text"> </v-footer>
  </v-app>
</template>
<script>
import UserMenu from './UserMenu';

export default {
  name: 'TheContainer',
  components: { UserMenu },
  computed: {},

  data: () => ({
    //
    drawer: false,
    userMenu: {
      show: false,
    },
  }),

  methods: {
    getEnabled(funcName) {
      // FIXME メニューを消すのみなので、router内でもチェックするように
      return this.$store.getters.user.authorityTypes.map((a) => a.text).includes(funcName);
    },
  },
  mounted() {},
};
</script>